
import {defineComponent, onMounted} from "vue";
import EditProduct from "../components/EditProduct.vue";
import {setPageHeader} from "@/core/helpers/toolbar";

export default defineComponent({
    components: {
        EditProduct
    },
    setup() {
        onMounted(() => {
            setPageHeader({
                title: "message.EDIT message.PRODUCT",
                actionButton: null,
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.CATALOG", link: "#"},
                    { name: "message.PRODUCTS", link: "/catalog/products"},
                    { name: "message.EDIT message.PRODUCT",}
                ]
            });
        })
    }
});
