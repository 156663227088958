
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { Input, Select, Image, Radio, Checkbox } from '@/components/input-elements';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { Apollo, Notify } from '@/core/services';
import { GET_MANUFACTURER, GET_META_TAGS_LIST } from '@/modules/catalog/manufacturers/graphql/Queriers';
import { IS_PARENT_CATEGORIES } from '@/modules/catalog/categories/graphql/Queries';
import { EditProduct } from '../interfaces/index';
import { UPDATE_PRODUCT } from '@/modules/catalog/products/graphql/Mutations';
import { EDIT_PRODUCT, GET_PRODUCT_FOR_RELATED } from '../graphql/Queries';
import ProductToPrice from './partials/ProdutToPrices.vue';
import ProductToPriceGroups from './partials/ProductToPriceGroups.vue';
import ProductToAttributes from './partials/ProductToAttributes.vue';
import ProductToMedia from './partials/ProductToMedia.vue';
import ProductToDimension from './partials/ProductToDimension.vue';
import { GET_CUSTOMER_GROUP } from '@/modules/customer/customer-groups/graphql/Queries';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'products',
    components: {
        Input,
        Select,
        Image,
        Radio,
        Checkbox,
        ProductToPrice,
        ProductToPriceGroups,
        ProductToAttributes,
        ProductToMedia,
        ProductToDimension,
        InnerLoader
    },
    setup() {
        const radio2 = ref(1);
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const renderAttribute = ref(false);
        const locales = ref([]) as Record<any, any>;
        const locale = ref('da');
        const previewImage = ref([]) as Record<any, any>;
        const loader = ref(false);
        const visible = ref(false);
        const index = ref(1);
        const images = ref([]) as Record<any, any>;
        const sites = ref([]) as Record<any, any>;
        const selectedSites = ref([]) as Record<any, any>;
        const manufacturers = ref([]) as Record<any, any>;
        const meta_tag_list = ref([]) as Record<any, any>;
        const locale_meta_tags = ref([]) as Record<any, any>;
        const categories = ref([]) as Record<any, any>;
        const inquires = ref([]) as Record<any, any>;
        const combinations = ref([]) as Record<any, any>;
        const editProductForm = ref<null | HTMLFormElement>(null);
        const price = ref(null) as Record<any, any>;
        const taxRule = ref(0) as Record<any, any>;
        const editorForm = ref<null | HTMLFormElement>(null);
        const tabs = ref('0');
        const limitCart = ref([]) as Record<any, any>;
        const validate = ref(false);
        const editorFormMeta = ref<null | HTMLFormElement>(null);
        const relatedProducts = ref([]);
        const variations = ref([]) as Record<any, any>;
        const priceGroupsList = ref([]) as Record<any, any>;
        const pricesList = ref([]) as Record<any, any>;
        const products = ref([]) as Record<any, any>;
        const dimensions = ref([]) as Record<any, any>;
        const status = ref();
        const customerGroups = ref([]) as Record<any, any>;
        const customerGroupsList = ref([]) as Record<any, any>;
        const i18n = useI18n();

        const product = ref<EditProduct>({
            name: '',
            label: '',
            product_number: '',
            sites: [],
            quantity: 0,
            inquiry: 0,
            price: null,
            manufacturer: null,
            description: '',
            meta_title: '',
            images_id: [],
            meta_tags: [],
            categories: [],
            meta_description: '',
            related_products: [],
            multiple: [],
            limit: [],
            taxes_rule_id: null,
            id: null
        });

        // Store name in Danish and English
        const jsonNameField = ref({}) as Record<any, any>;

        const jsonDescriptionField = ref({}) as Record<any, any>;

        const jsonLabelField = ref({}) as Record<any, any>;

        const jsonMetaTitleField = ref({}) as Record<any, any>;

        const jsonMetaTagsField = ref({}) as Record<any, any>;

        const jsonMetaDescriptionField = ref({}) as Record<any, any>;

        const resetForm = () => {
            // empty json fields data
            pricesList.value = [];
            variations.value = [];
            dimensions.value = [];
            priceGroupsList.value = [];
            for (const i of Object.getOwnPropertyNames(jsonNameField.value)) {
                jsonNameField.value[i].name = '';
                jsonDescriptionField.value[i].name = '';
                jsonMetaTitleField.value[i].name = '';
                jsonMetaTagsField.value[i].name = '';
                jsonMetaDescriptionField.value[i].name = '';
                jsonLabelField.value[i].name = '';
            }
        };

        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });

                jsonNameField.value[element.locale] = { name: '' };
                jsonDescriptionField.value[element.locale] = { name: '' };
                jsonLabelField.value[element.locale] = { name: '' };
                jsonMetaTitleField.value[element.locale] = { name: '' };
                jsonMetaTagsField.value[element.locale] = { name: '' };
                jsonMetaDescriptionField.value[element.locale] = { name: '' };
            });
        };

        loadLang();

        const statusOptions = ref([
            {
                label: 'message.ACTIVE',
                value: 1
            },
            {
                label: 'message.INACTIVE',
                value: 0
            }
        ]) as Record<any, any>;

        combinations.value = [
            {
                label: 'message.SIMPLE_PRODUCT',
                value: 1
            },
            {
                label: 'message.PRODUCT_WITH_COMBINATIONS',
                value: 2
            }
        ];

        inquires.value = [
            {
                label: 'message.YES',
                value: 1
            },
            {
                label: 'message.NO',
                value: 0
            }
        ];

        const editProduct = (uuid: string) => {
            loader.value = true;
            Apollo.watchQuery({
                query: EDIT_PRODUCT,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                variables: {
                    id: uuid
                }
            }).subscribe(({ data }) => {
                const editProduct = JSON.parse(data.edit_product);
                product.value.id = editProduct.id;
                status.value = editProduct.status;
                product.value.product_number = editProduct.economic_number;
                product.value.inquiry = editProduct.inquiry_product;
                product.value.quantity = editProduct.available_stock;
                price.value = editProduct.price;
                taxRule.value = editProduct.tax_rule_id == null ? 0 : editProduct.tax_rule_id;

                if (editProduct.product_to_manufacturer?.length > 0) {
                    product.value.manufacturer = editProduct.product_to_manufacturer[0].id;
                }

                if (editProduct.product_to_categories?.length > 0) {
                    product.value.categories = [];
                    editProduct.product_to_categories.forEach(element => {
                        product.value.categories.push(element.id);
                    });
                }

                if (editProduct.product_to_sites?.length > 0) {
                    product.value.sites = [];
                    editProduct.product_to_sites.forEach(element => {
                        product.value.sites.push(element.id);
                    });
                }

                if (editProduct.product_to_prices?.length > 0) {
                    pricesList.value = [];
                    editProduct.product_to_prices.forEach(element => {
                        pricesList.value.push({
                            id: element.id,
                            price: element.price,
                            site_name: element.site.name,
                            site_id: element.site.id,
                            tax: element.price + element.tax,
                            tax_rule_id: element.tax_rule_id,
                            tax_on_price: element.tax
                        });
                    });
                }

                if (editProduct.product_to_price_groups?.length > 0) {
                    priceGroupsList.value = [];
                    editProduct.product_to_price_groups.forEach(element => {
                        priceGroupsList.value.push({
                            id: element.id,
                            name: element.price_group.name,
                            value: element.price_group_id,
                            price: element.special_price
                        });
                    });
                } else {
                    priceGroupsList.value = [];
                }

                if (editProduct.related_products?.length > 0) {
                    product.value.related_products = [];
                    editProduct.related_products.forEach(element => {
                        product.value.related_products.push(element.related_product_id);
                    });
                }

                if (editProduct.media?.length > 0) {
                    previewImage.value = [];
                    images.value = [];
                    editProduct.media.forEach(element => {
                        previewImage.value.push({
                            id: element.id,
                            src: element.file_path
                        });
                        images.value.push(element.id);
                    });
                }

                if (editProduct.media?.length > 0) {
                    previewImage.value = [];
                    images.value = [];
                    editProduct.media.forEach(element => {
                        previewImage.value.push({
                            id: element.id,
                            src: element.file_path
                        });
                        images.value.push(element.id);
                    });
                }

                if (editProduct.product_to_dimensions?.length > 0) {
                    dimensions.value = [];
                    editProduct.product_to_dimensions.forEach(element => {
                        dimensions.value.push({
                            name: element.dimension.name,
                            unit: element.dimension.measurement,
                            value: element.value,
                            dimension_id: element.dimension.id
                        });
                    });
                }

                if (editProduct.cart_limit_products?.length > 0) {
                    limitCart.value = [];
                    product.value.multiple = [];
                    product.value.limit;
                    editProduct.cart_limit_products.forEach(element => {
                        limitCart.value[element.site_id] = true;
                        product.value.multiple[element.site_id] = element.multiple_limit;
                        product.value.limit[element.site_id] = element.multiples;
                    });
                }

                if (editProduct.product_attribute_values?.length > 0) {
                    variations.value = [];
                    editProduct.product_attribute_values.forEach((element, index) => {
                        variations.value.push({
                            id: element.id,
                            attribute_name: JSON.parse(element.attributes.name)[`${locale.value}`].name,
                            attribute_id: element.attribute_id,
                            attribute_value_id: element.attribute_value_id,
                            attribute_value_name: JSON.parse(element.attribute_values.name)[`${locale.value}`].name,
                            attribute_value: element.attribute_values.variant_value,
                            attribute_value_type: element.attribute_values.variant_type,
                            economic_number: element.economic_number,
                            sku: element.sku,
                            stock: element.stock,
                            price: element.price,
                            images: element.media == null ? [] : [{ src: element.media.file_path, id: element.media.id }]
                        });
                    });
                    radio2.value = 2;
                    renderAttribute.value = true;
                }

                if (editProduct.product_to_customer_groups?.length > 0) {
                    editProduct.product_to_customer_groups.forEach(element => {
                        customerGroups.value.push(element.id);
                    });
                }

                // json decode name
                const jsonDecodeName = JSON.parse(editProduct.product_description.name);
                for (const i in jsonDecodeName) {
                    i == 'da' ? (product.value.name = jsonDecodeName[i].name) : '';
                    jsonNameField.value[i].name = jsonDecodeName[i].name;
                }

                const jsonDecodeLabel = JSON.parse(editProduct.label_new);
                for (const i in jsonDecodeLabel) {
                    i == 'da' ? (product.value.label = jsonDecodeLabel[i].name) : '';
                    jsonLabelField.value[i].name = jsonDecodeLabel[i].name;
                }

                const jsonDecodeDescription = JSON.parse(editProduct.product_description.description);
                for (const i in jsonDecodeDescription) {
                    if (i == 'da') {
                        product.value.description = jsonDecodeDescription[i].name;
                        editorForm.value?.setHTML(product.value.description);
                    } else {
                        jsonDescriptionField.value[i].name = jsonDecodeDescription[i].name;
                    }
                }

                const jsonDecodeMetaTitle = JSON.parse(editProduct.product_description.meta_title);
                for (const i in jsonDecodeMetaTitle) {
                    i == 'da' ? (product.value.meta_title = jsonDecodeMetaTitle[i].name) : '';
                    jsonMetaTitleField.value[i].name = jsonDecodeMetaTitle[i].name;
                }

                const jsonDecodeMetaDescription = JSON.parse(editProduct.product_description.meta_description);
                for (const i in jsonDecodeMetaDescription) {
                    if (i == 'da') {
                        product.value.meta_description = jsonDecodeMetaDescription[i].name;
                        editorFormMeta.value?.setHTML(product.value.meta_description);
                    } else {
                        jsonMetaDescriptionField.value[i].name = jsonDecodeMetaDescription[i].name;
                    }
                }

                const jsonDecodeMetaTags = JSON.parse(editProduct.product_description.tags);
                for (const i in jsonDecodeMetaTags) {
                    i == 'da' ? (product.value.meta_tags = jsonDecodeMetaTags[i].name) : '';
                    jsonMetaTagsField.value[i].name = jsonDecodeMetaTags[i].name;
                }

                loader.value = false;
            });
        };

        onMounted(() => {
            resetForm();
            editProduct(String(route.params.uuid));
        });

        const getCustomerGroups = () => {
            // loader.value = true;
            Apollo.watchQuery({
                query: GET_CUSTOMER_GROUP,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                customerGroupsList.value = [];
                data.customer_groups.data.forEach(element => {
                    customerGroupsList.value.push({
                        value: element.id,
                        desc: element.name
                    });
                });

                // loader.value = false;
            });
        };

        getCustomerGroups();

        const productsList = ref([]) as Record<any, any>;
        const getProducts = () => {
            // loader.value = true;
            Apollo.watchQuery({
                query: GET_PRODUCT_FOR_RELATED,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                productsList.value = data.products.data;
                // loader.value = false;
            });
        };

        getProducts();

        // Get All Sites
        watchEffect(() => {
            sites.value = [];
            selectedSites.value = [];
            const getSites = store.getters.getAllSites.data.sites;
            getSites.forEach(element => {
                sites.value.push({
                    label: element.name,
                    value: element.id
                });
            });
            product.value.sites.forEach((element1, index1) => {
                getSites.forEach((element2, index2) => {
                    if (element1 == element2.id)
                        selectedSites.value.push({
                            label: element2.name,
                            value: element2.id
                        });
                });
            });

            locale_meta_tags.value = [];

            meta_tag_list.value.forEach((meta_tag, index) => {
                if (JSON.parse(meta_tag?.tags)) {
                    const locale_base_meta_tag = JSON.parse(meta_tag?.tags)[locale.value]?.name;
                    for (const i in locale_base_meta_tag) {
                        locale_meta_tags.value.push({
                            label: locale_base_meta_tag[i],
                            value: locale_base_meta_tag[i]
                        });
                    }
                }
            });
        });

        // Get All Manufacturer
        const getManufactures = () => {
            Apollo.watchQuery({
                query: GET_MANUFACTURER,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only'
            }).subscribe(({ data }) => {
                manufacturers.value = [];
                data?.manufacturers.data.forEach(element => {
                    manufacturers.value.push({
                        label: element.name,
                        value: element.id
                    });
                });
            });
        };

        getManufactures();

        const getMetaTagsList = () => {
            Apollo.watchQuery({
                query: GET_META_TAGS_LIST,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only'
            }).subscribe(({ data }) => {
                manufacturers.value = [];
                meta_tag_list.value = JSON.parse(data?.meta_tags_list);
            });
        };

        getMetaTagsList();

        const categoriesList = ref([]);
        //Get All Categories
        const getCategories = () => {
            Apollo.watchQuery({
                query: IS_PARENT_CATEGORIES,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only'
            }).subscribe(({ data }) => {
                categoriesList.value = [];
                categoriesList.value = data.categories?.data;
            });
        };

        getCategories();

        watchEffect(() => {
            categories.value = [];
            categoriesList.value.forEach((element: Record<any, any>) => {
                let defaultLocale = '';
                if (JSON.parse(element.name)[`${locale.value}`] == undefined) {
                    defaultLocale = 'da';
                } else {
                    defaultLocale = locale.value;
                }

                if (element.parent_id == 0) {
                    categories.value.push({
                        label: JSON.parse(element.name)[`${defaultLocale}`].name,
                        value: element.id
                    });
                    if (element?.children?.length > 0) {
                        element?.children.forEach(ele => {
                            if (ele?.children?.length > 0) {
                                ele.children.forEach(el => {
                                    categories.value.push({
                                        label:
                                            JSON.parse(element.name)[`${defaultLocale}`].name +
                                            ' -> ' +
                                            JSON.parse(ele.name)[`${defaultLocale}`].name +
                                            ' -> ' +
                                            JSON.parse(el.name)[`${defaultLocale}`].name,
                                        value: el.id
                                    });
                                });
                            }
                            categories.value.push({
                                label: JSON.parse(element.name)[`${defaultLocale}`].name + ' -> ' + JSON.parse(ele.name)[`${defaultLocale}`].name,
                                value: ele.id
                            });
                        });
                    }
                } else {
                    categories.value.push({
                        label: JSON.parse(element.name)[`${defaultLocale}`].name,
                        value: element.id
                    });
                }
            });

            products.value = [];
            productsList.value.forEach((element: Record<any, any>) => {
                let defaultLocale = '' as any;
                if (JSON.parse(element.description.name)[`${locale.value}`] == undefined) {
                    defaultLocale = 'da';
                } else {
                    defaultLocale = locale.value;
                }
                if (element.uuid == String(route.params.uuid)) {
                    return;
                }
                products.value.push({
                    label: JSON.parse(element.description.name)[`${defaultLocale}`].name,
                    value: element.id
                });
            });
        });

        const changeCombination = e => {
            e == 2 ? (renderAttribute.value = true) : (renderAttribute.value = false);
        };

        const changeLang = (locale: string) => {
            product.value.name = jsonNameField.value[locale].name;
            product.value.label = jsonLabelField.value[locale].name;
            product.value.description = jsonDescriptionField.value[locale].name;
            editorForm.value?.setHTML(product.value.description);
            product.value.meta_title = jsonMetaTitleField.value[locale].name;
            product.value.meta_tags = jsonMetaTagsField.value[locale].name;
            product.value.meta_description = jsonMetaDescriptionField.value[locale].name;
            editorFormMeta.value?.setHTML(product.value.meta_description);
        };

        const updateValue = (value: string, action: string) => {
            switch (action) {
                case 'name':
                    jsonNameField.value[locale.value].name = value;
                    break;
                case 'label':
                    jsonLabelField.value[locale.value].name = value;
                    break;
                case 'metaTitle':
                    jsonMetaTitleField.value[locale.value].name = value;
                    break;
                case 'metaTag':
                    jsonMetaTagsField.value[locale.value].name = value;
                    break;
                default:
                    return false;
            }
        };

        const onEditorChange = (e: Record<any, any>, action: string) => {
            switch (action) {
                case 'description':
                    jsonDescriptionField.value[locale.value].name = product.value.description;
                    break;
                case 'metaDescription':
                    jsonMetaDescriptionField.value[locale.value].name = product.value.meta_description;
                    break;
                default:
                    return false;
            }
        };

        const customValidations = () => {
            if (previewImage.value?.length == 0) {
                tabs.value = '2';
                Notify.error(i18n.t('message.ADD_IMAGES'));
                return false;
            }

            if (product.value.sites?.length == 0) {
                renderAttribute.value == true ? (tabs.value = '4') : (tabs.value = '3');
                Notify.error(`${i18n.t('message.SITES')} ${i18n.t('message.IS_REQUIRED')}`);
                return false;
            }

            if (pricesList.value?.length == 0) {
                renderAttribute.value == true ? (tabs.value = '5') : (tabs.value = '4');
                Notify.error(`${i18n.t('message.PRICE')} ${i18n.t('message.IS_REQUIRED')}`);
                return false;
            }

            if ((product.value.description == '' && validate.value == true) || product.value.description === '<p><br></p>') {
                tabs.value = '0';
                Notify.error(`${i18n.t('message.PRODUCT')} ${i18n.t('message.DESCRIPTION')} ${i18n.t('message.IS_REQUIRED')}`);
                return false;
            }

            if (product.value.quantity == null) {
                tabs.value = '0';
                Notify.error(`${i18n.t('message.PRODUCT')} ${i18n.t('message.QUANTITY')} ${i18n.t('message.IS_REQUIRED')}`);
                return false;
            }

            return true;
        };

        const handlerSubmitForm = () => {
            validate.value = true;
            editProductForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const validator = customValidations();
                    loader.value = true;
                    const cartLimitProduct = [] as Record<any, any>;
                    limitCart.value.forEach((ele, index) => {
                        if (ele == true) {
                            const site = sites.value.find(ele => ele.value == index);
                            cartLimitProduct.push({
                                site_id: site.value,
                                multiple_limit: product.value.limit[index] == undefined ? 0 : product.value.multiple[index],
                                multiples: product.value.multiple[index] == undefined ? 0 : product.value.limit[index]
                            });
                        }
                    });

                    for (const i in jsonNameField.value) {
                        if (jsonNameField.value[i].name == '') {
                            jsonNameField.value[i].name = product.value.name;
                        }
                    }

                    for (const i in jsonDescriptionField.value) {
                        if (jsonDescriptionField.value[i].name == '') {
                            jsonDescriptionField.value[i].name = product.value.description;
                        }
                    }

                    for (const i in jsonLabelField.value) {
                        if (jsonLabelField.value[i].name == '') {
                            jsonLabelField.value[i].name = product.value.label;
                        }
                    }

                    for (const i in jsonMetaTitleField.value) {
                        if (jsonMetaTitleField.value[i].name == '') {
                            jsonMetaTitleField.value[i].name = product.value.meta_title;
                        }
                    }

                    for (const i in jsonMetaDescriptionField.value) {
                        if (jsonMetaDescriptionField.value[i].name == '') {
                            jsonMetaDescriptionField.value[i].name = product.value.meta_description;
                        }
                    }

                    for (const i in jsonMetaTagsField.value) {
                        if (jsonMetaTagsField.value[i].name == '') {
                            jsonMetaTagsField.value[i].name = product.value.meta_tags;
                        }
                    }

                    if (radio2.value == 2) {
                        product.value.quantity = 0;
                        for (const i in variations.value) {
                            if (variations.value[i].stock) {
                                product.value.quantity = product.value.quantity + parseInt(variations.value[i].stock);
                            }
                        }
                    }

                    if (validator == true) {
                        const formData = {
                            id: product.value.id,
                            name: JSON.stringify(jsonNameField.value),
                            description: JSON.stringify(jsonDescriptionField.value),
                            label: JSON.stringify(jsonLabelField.value),
                            product_number: product.value.product_number,
                            related_product: product.value.related_products,
                            categories: product.value.categories,
                            cart_limit_product: JSON.stringify(cartLimitProduct),
                            quantity: product.value.quantity,
                            sites: product.value.sites,
                            manufacturer: product.value.manufacturer,
                            inquiry: product.value.inquiry,
                            meta_title: JSON.stringify(jsonMetaTitleField.value),
                            meta_tags: JSON.stringify(jsonMetaTagsField.value),
                            meta_description: JSON.stringify(jsonMetaDescriptionField.value),
                            images: images.value,
                            variations: JSON.stringify(variations.value),
                            price_groups: JSON.stringify(priceGroupsList.value),
                            prices: JSON.stringify(pricesList.value),
                            dimensions: JSON.stringify(dimensions.value),
                            status: status.value,
                            customer_groups: customerGroups.value
                        };

                        await Apollo.mutate({
                            mutation: UPDATE_PRODUCT,
                            variables: {
                                input: formData
                            },
                            update: (store, { data: { update_product } }) => {
                                Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                                router.push({ name: 'products' });
                                loader.value = false;
                            }
                        }).catch(() => {
                            loader.value = false;
                        });
                    } else {
                        loader.value = false;
                    }
                } else {
                    tabs.value = '0';
                }
            });
        };

        const getProductAttributes = (data: Record<any, any>) => {
            variations.value = [];
            variations.value = data;
            product.value.quantity = 0;
            variations.value.forEach(element => {
                product.value.quantity += parseInt(element.stock);
            });
        };

        const getPrice = (data: Record<any, any>) => {
            pricesList.value = [];
            pricesList.value = data;
        };

        const getPriceGroupLists = (data: Record<any, any>) => {
            priceGroupsList.value = [];
            priceGroupsList.value = data;
        };

        const getProductMedia = (data: Record<any, any>) => {
            images.value = [];
            images.value = data;
        };

        const getDimensions = (data: Record<any, any>) => {
            dimensions.value = [];
            dimensions.value = data;
        };

        const goBack = () => {
            router.push({ name: 'products' });
        };

        return {
            editProductForm,
            product,
            radio2,
            renderAttribute,
            categories,
            locales,
            locale,
            previewImage,
            index,
            visible,
            sites,
            manufacturers,
            inquires,
            combinations,
            editorForm,
            tabs,
            limitCart,
            validate,
            editorFormMeta,
            relatedProducts,
            variations,
            pricesList,
            priceGroupsList,
            statusOptions,
            status,
            customerGroupsList,
            locale_meta_tags,
            customerGroups,
            loader,
            products,
            dimensions,
            selectedSites,
            goBack,
            getDimensions,
            onEditorChange,
            getProductAttributes,
            getPrice,
            getPriceGroupLists,
            getProductMedia,
            changeLang,
            updateValue,
            handlerSubmitForm,
            changeCombination
        };
    }
});
